<template>
	<div>
		<CCard class="mb-0">
			<CCardBody class="order-form">
				<div v-if="isUpdate" class="shop__header">
					<h5 class="mb-3">{{ getTitle }}</h5>
					<h5 class="mb-3"> 1. {{ $t("OrdersTitle.Request") }} </h5>
				</div>
				<div v-if="!isUpdate" class="shop__header">
					<h5 v-if="!$route.query.update_order" class="mb-3">
						{{ $t("OrdersTitle.NewOrder") }}
					</h5>
					<h5 v-else class="mb-3">
						{{ $t("OrdersTitle.NewUpdate") }}
					</h5>
				</div>
				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom">
							<div class="custom__input">
								<CRow>
									<CCol v-if="addAnUpdate" class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- order ID  -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes, errors }" mode="lazy">
												<div class="form-group row">
													<label for="title" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.order_id")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="update_id"
															v-model="orderForm.orderID"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.order_id')"
															autocomplete="update_id"
															disabled
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol v-if="isUpdate" class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- Update ID  -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes, errors }" mode="lazy">
												<div class="form-group row">
													<label for="update_id" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.update_id")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="update_id"
															v-model="orderForm.updateID"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.update_id')"
															autocomplete="update_id"
															disabled
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- form ID  -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes, errors }">
												<div class="form-group row">
													<label for="form_id" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.form_id")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="form_id"
															v-model.trim="orderForm.formID"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.form_id')"
															autocomplete="username"
															disabled
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- created date  -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes, errors }">
												<div class="form-group row">
													<label for="create_date" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.create_date")
													}}</label>
													<div :class="['col-12 col-md-8 position-relative', classes]">
														<date-picker
															id="create_date"
															v-model="createdDate"
															value-type="format"
															class="w-100"
															disabled
															:format="formatDate"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- received date -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="receivedDate"
												name="form_order_id"
												:rules="`required`"
											>
												<div class="form-group required row">
													<label for="received_date" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.received_date")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<date-picker
															id="received_date"
															v-model="orderForm.receivedDate"
															value-type="format"
															class="w-100"
															disabled
															:format="formatDate"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- status  -->
										<div class="form-group position-relative">
											<ValidationProvider
												ref="province"
												v-slot="{ classes, errors }"
												name="order_status"
												:rules="`requiredExport`"
											>
												<div class="form-group required row">
													<label for="status" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.status")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<multiselect
															id="status"
															v-model="orderForm.status"
															:options="listStatus"
															:close-on-select="true"
															:show-labels="false"
															:allow-empty="false"
															:searchable="false"
															:placeholder="$t('OrderFields.status')"
															:disabled="isDisabled || newOrder"
															label="name"
															track-by="value"
															class="custom_multiselect"
															@select="selectStatus"
															@remove="clearSelected('status')"
														>
															<template #noOptions>
																{{ $t("DropDown.NoOptions") }}
															</template>
															<template #noResult>
																{{ $t("DropDown.NoResult") }}
															</template>
															<!-- <template slot="clear">
																<div
																	v-if="orderForm.status"
																	class="multiselect__clear"
																	@mousedown.prevent.stop="clearSelected('status')"
																>
																	<font-awesome-icon
																		:icon="['fas', 'times']"
																		:title="$t('Action.Clear')"
																	/>
																</div>
															</template> -->
														</multiselect>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol v-if="displayWithRole" class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- email -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="email"
												name="order_email"
												:rules="`requiredExport|max:${ORDER_RULE.EMAIL}|isEmail`"
											>
												<div class="form-group required row">
													<label for="email" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.email")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="email"
															v-model.trim="orderForm.customerEmail"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.email')"
															autocomplete="email"
															:maxlength="ORDER_RULE.EMAIL"
															:disabled="isDisabled"
															@keydown="handleSearchCustomer"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol v-if="displayWithRole" class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- customer name -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes, errors }" name="order_customer_name">
												<div class="form-group row">
													<label for="customer_name" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.customer_name")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="customer_name"
															v-model="orderForm.customerName"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.customer_name')"
															autocomplete="customer_name"
															disabled
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- customer id -->
										<div class="form-group position-relative">
											<ValidationProvider
												ref="province"
												v-slot="{ classes, errors }"
												name="order_customer_ID"
											>
												<div class="form-group row">
													<label for="customer_id" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.customer_id")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="customer_id"
															v-model="orderForm.customerId"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.customer_id')"
															autocomplete="customer_id"
															disabled
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- title  -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												name="order_title"
												:rules="`requiredExport|max:${ORDER_RULE.TITLE}`"
												mode="lazy"
											>
												<div class="form-group required row">
													<label for="title" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.title")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="title"
															v-model.trim="orderForm.title"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.title')"
															autocomplete="source"
															:maxlength="ORDER_RULE.TITLE"
															:disabled="isDisabled"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
								</CRow>

								<!-- description  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_description"
										:rules="`requiredExport|max:${ORDER_RULE.DESCRIPTION}`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="description" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.description")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="description"
													v-model.trim="orderForm.description"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.description')"
													autocomplete="description"
													:maxlength="ORDER_RULE.DESCRIPTION"
													:disabled="isDisabled"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
												<div v-if="!isUpdate || isUpdateOrder" class="text-right">
													<button
														class="px-4 btn btn-outline-custom"
														type="button"
														@click="translateText"
													>
														{{ $t("Button.Translate") }}
													</button>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- description translate -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_description_translate"
										mode="lazy"
									>
										<div class="form-group row">
											<label for="description_translate" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.descriptionTranslate")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="description_translate"
													v-model.trim="orderForm.translatedDescription"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.descriptionTranslate')"
													autocomplete="description_translate"
													:disabled="isDisabled"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- type  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_type"
										:rules="`requiredExport`"
									>
										<div class="form-group required row">
											<label for="type" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.type")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<multiselect
													id="type"
													v-model="orderTypes"
													:options="types"
													multiple
													label="id"
													track-by="id"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="true"
													:taggable="false"
													:placeholder="$t('OrderFields.type')"
													:disabled="isShowSelectType"
													class="custom_multiselect"
													@remove="clearSelected('type', $event)"
												>
													<!-- @close="closeDropDown('type')" -->
													<!-- @open="getTypes()" -->
													<!-- @select="selectType" -->
													<template #noOptions>
														{{ $t("DropDown.NoOptions") }}
													</template>
													<template #noResult>
														{{ $t("DropDown.NoResult") }}
													</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<template v-if="orderTypesSelected && orderTypesSelected.length">
									<!-- amount -->
									<div class="form-group position-relative">
										<ValidationProvider v-slot="{ classes, errors }" name="order_amount">
											<div class="form-group row">
												<label for="amount" class="col-md-2 col-12 col-form-label">{{
													$t("OrderFields.amount")
												}}</label>
												<div :class="['col-md-10 col-12', classes]">
													<TableAmount
														:disabled="isDisabled"
														:order-types="orderTypesSelected"
														:new-update-has-status-revision="newUpdateHasStatusRevision"
														@amount-change="amountChange"
													/>
													<div class="error-mess">
														{{ errors[0] }}
													</div>
												</div>
											</div>
										</ValidationProvider>
									</div>

									<template v-if="isSuperAdmin">
										<!-- system price -->
										<div class="form-group position-relative">
											<ValidationProvider v-slot="{ classes }" name="order_system_price">
												<div class="form-group row">
													<label for="amount" class="col-md-2 col-12 col-form-label">{{
														$t("OrderFields.systemPrice")
													}}</label>
													<div :class="['col-md-10 col-12', classes]">
														<div class="system-price h-100 d-flex align-items-center">{{
															orderForm.cost
														}}</div>
													</div>
												</div>
											</ValidationProvider>
										</div>

										<!-- actual price -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="actualPrice"
												name="order_actual_price"
												:rules="`isNumber|max:${ORDER_RULE.AMOUNT}`"
											>
												<div class="form-group row">
													<label for="amount" class="col-md-2 col-12 col-form-label">{{
														$t("OrderFields.actualPrice")
													}}</label>
													<div :class="['col-md-10 col-12', classes]">
														<input
															id="actual_price"
															v-model.trim="orderForm.price"
															type="text"
															class="form-control"
															autocomplete="actual_price"
															:disabled="isDisabled"
															:maxlength="ORDER_RULE.AMOUNT"
															@paste="handleOnPaste($event)"
															@keydown="checkValidPhone($event)"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</template>
								</template>
								<CRow>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- time to do -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="time"
												name="order_time"
												:rules="`requiredExport|isNumber`"
											>
												<div class="form-group required row">
													<label for="time_to_do" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.time_to_do")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<input
															id="time_to_do"
															v-model.trim="orderForm.time"
															type="text"
															class="form-control"
															:placeholder="$t('OrderFields.time_to_do')"
															autocomplete="time_to_do"
															:disabled="isDisabled"
															@keyup="handleSelectTime"
															@paste="handleOnPaste"
															@keydown="checkValidPhone($event)"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
									<CCol class="col-md-6 col-lg-6 col-xl-6 col-sm-12">
										<!-- deadline -->
										<div class="form-group position-relative">
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="deadline"
												name="order_deadline"
												:rules="`requiredExport`"
											>
												<div class="form-group required row">
													<label for="deadline" class="col-md-4 col-12 col-form-label">{{
														$t("OrderFields.deadline")
													}}</label>
													<div :class="['col-md-8 col-12', classes]">
														<date-picker
															id="deadline"
															v-model="orderForm.deadLine"
															value-type="format"
															type="datetime"
															class="w-100"
															disabled
															:format="formatDate"
															:editable="false"
															:placeholder="$t('FormsPlacehoder.date_format')"
														/>
														<div class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</div>
									</CCol>
								</CRow>

								<!-- rule  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="order_rule"
										:rules="`max:${ORDER_RULE.RULE}`"
										mode="lazy"
									>
										<div class="form-group row">
											<label for="rule" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.rule")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="rule"
													v-model.trim="orderForm.rule"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.rule')"
													autocomplete="rule"
													:maxlength="ORDER_RULE.RULE"
													:disabled="isDisabled"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- upload file -->
								<UploadFiles
									:name="TYPE_UPLOAD.REQUEST"
									:is-upload-success="isPressConfirm"
									:show-delete="checkIsUpdate"
									:show-download="true"
									:show-add="checkIsUpdate"
									:is-update="isUpdateOrder || !isUpdate"
									:is-required="false"
									:files="uploadFiles"
									@handleUploadFiles="handleUploadFiles"
									@handleDeleteFile="handleDeleteFile"
									@total-file="getTotalFiles"
								/>

								<!-- link dropbox -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="dropbox"
										name="order_dropbox"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="order_dropbox" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDropbox")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="order_dropbox"
														v-model.trim="orderForm.dropbox"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('OrderFields.linkDropbox')"
														autocomplete="order_dropbox"
														:disabled="isUpdate"
													/>
													<button
														:disabled="isUpdate || !orderForm.dropbox"
														class="px-2 btn btn-outline-custom icon"
														type="button"
														@click="addLink('dropbox')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														orderForm.dropboxSharedFolderLinks &&
														orderForm.dropboxSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(dropboxLink, index1) in orderForm.dropboxSharedFolderLinks"
														:key="index1"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
														:class="{ 'disabled-link': isUpdate }"
													>
														<a :href="dropboxLink" target="_blank" class="dropbox-url">{{
															dropboxLink
														}}</a>
														<button
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															:disabled="isUpdate"
															@click="removeLink('dropbox', index1)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link drive -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="drive"
										name="order_drive"
										mode="lazy"
										:rules="`isUrl`"
									>
										<div class="form-group row">
											<label for="order_drive" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.linkDrive")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<div class="position-relative">
													<input
														id="order_drive"
														v-model.trim="orderForm.drive"
														type="text"
														class="form-control pr-5"
														:placeholder="$t('OrderFields.linkDrive')"
														autocomplete="order_drive"
														:disabled="isUpdate"
													/>
													<button
														:disabled="isUpdate || !orderForm.drive"
														class="px-2 btn btn-outline-custom icon"
														type="button"
														@click="addLink('drive')"
													>
														<font-awesome-icon :icon="['fas', 'plus']" :title="$t('Action.Add')" />
													</button>
												</div>
												<div v-if="errors.length" class="error-mess">
													{{ errors[0] }}
												</div>
												<div
													v-if="
														orderForm.driveSharedFolderLinks &&
														orderForm.driveSharedFolderLinks.length
													"
													class="py-3 dropbox-list"
												>
													<div
														v-for="(driveLink, index2) in orderForm.driveSharedFolderLinks"
														:key="index2"
														class="d-flex justify-content-between align-items-center dropbox-item mb-1"
														:class="{ 'disabled-link': isUpdate }"
													>
														<a :href="driveLink" target="_blank" class="dropbox-url">{{
															driveLink
														}}</a>
														<button
															:disabled="isUpdate"
															class="px-2 btn btn-outline-danger btn-delete"
															type="button"
															@click="removeLink('drive', index2)"
														>
															<font-awesome-icon
																:icon="['fas', 'trash-alt']"
																:title="$t('Action.Delete')"
															/>
														</button>
													</div>
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<div v-if="isUpdate" class="shop__header">
									<h5 class="mb-3"> 2. {{ $t("OrdersTitle.Result") }} </h5>
								</div>
								<!-- result file -->
								<UploadFiles
									v-if="isUpdate"
									:name="TYPE_UPLOAD.RESULT"
									:is-upload-success="isPressConfirm"
									:show-delete="checkIsUpdate"
									:show-download="true"
									:show-add="checkIsUpdate"
									:is-update="isUpdateOrder"
									:files="resultFiles"
									:is-required="false"
									:label="$t('OrderFields.file_result')"
									@handleUploadFiles="handleUploadResultFiles"
									@handleDeleteFile="handleDeleteResultFile"
									@total-file="getTotalFiles"
								/>
								<!-- switch complete -->
								<div v-if="isUpdateOrder" class="form-group position-relative">
									<ValidationProvider v-slot="{ classes }" name="order_complete_update" mode="lazy">
										<div class="form-group row">
											<label for="complete_update" class="col-md-2 col-sm-4 col-form-label">{{
												$t("OrderFields.complete_update")
											}}</label>
											<div :class="['col-md-10 col-sm-8 d-flex align-items-center', classes]">
												<b-form-checkbox
													v-model="isCompleteUpdate"
													name="switch"
													switch
													class="switch"
													@change="changeIsCompleteUpdate"
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- link result -->
								<div v-if="isCompletedOrder" class="form-group position-relative">
									<div class="form-group row">
										<label for="link_result" class="col-md-2 col-12 col-form-label">{{
											$t("OrderFields.link_result")
										}}</label>
										<div class="col-md-10 col-12">
											<a :href="orderForm.linkResult" target="_blank">{{ orderForm.linkResult }}</a>
										</div>
									</div>
								</div>

								<!-- Email template -->
								<div v-if="isCompletedOrder" class="form-group position-relative">
									<CRow>
										<CCol v-if="isUpdateOrder" class="col-md-3 col-lg-3 col-xl-3 col-sm-12">
											<ValidationProvider
												v-slot="{ classes }"
												name="order_email_to_customer"
												mode="lazy"
											>
												<div class="form-group row">
													<label for="email_to_customer" class="col-md-8 col-12 col-form-label">{{
														$t("OrderFields.email_to_customer")
													}}</label>
													<div :class="['col-md-3 col-12 d-flex align-items-center', classes]">
														<b-form-checkbox
															v-model="isSendEmail"
															name="checkbox"
															@change="ChangeIsSendEmail"
														/>
													</div>
												</div>
											</ValidationProvider>
										</CCol>
										<CCol
											class="col-12"
											:class="{ 'col-md-9 col-lg-9 col-xl-9 col-sm-12': isUpdateOrder }"
										>
											<ValidationProvider
												v-slot="{ classes, errors }"
												ref="email_select"
												name="order_email_template"
												mode="lazy"
												:rules="{ requiredExport: isSendEmail }"
											>
												<div class="form-group row">
													<label for="email_template" class="col-md-2 col-12 col-form-label">{{
														$t("OrderFields.email_template")
													}}</label>
													<div :class="['col-md-10 col-12', classes]">
														<multiselect
															id="type"
															v-model="orderForm.emailTemplateId"
															:options="emailTemplateList"
															label="id"
															track-by="id"
															:close-on-select="true"
															:show-labels="false"
															:allow-empty="true"
															:searchable="true"
															:placeholder="$t('OrderFields.email_template')"
															:disabled="disableEmailTemp"
															class="custom_multiselect"
															@remove="clearSelected('email_template')"
															@select="selectEmailTemplate"
															@close="closeDropDown('email')"
														>
															<!-- @open="getEmailTemplates()" -->
															<template #noOptions>
																{{ $t("DropDown.NoOptions") }}
															</template>
															<template #noResult>
																{{ $t("DropDown.NoResult") }}
															</template>
														</multiselect>
														<div v-if="errors.length" class="error-mess">
															{{ errors[0] }}
														</div>
													</div>
												</div>
											</ValidationProvider>
										</CCol>
									</CRow>
								</div>

								<!-- Email description -->
								<div v-if="isCompletedOrder" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										ref="email_description"
										name="order_email_description"
										mode="lazy"
										:rules="{ requiredExport: isSendEmail }"
									>
										<div class="form-group row">
											<label for="email_description" class="col-md-2 col-12 col-form-label">{{
												$t("OrderFields.email_description")
											}}</label>
											<div :class="['col-md-10 col-12', classes]">
												<textarea
													id="email_description"
													v-model.trim="orderForm.emailTemplateDescription"
													type="text"
													rows="6"
													class="form-control remove-resize"
													:placeholder="$t('OrderFields.email_description')"
													autocomplete="email_description"
													:disabled="disableEmailDescription"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
								<!-- checkbox upload to sever -->
								<div v-if="!isUpdate || isUpdateOrder" class="form-group position-relative">
									<ValidationProvider v-slot="{ classes }" name="order_upload_server" mode="lazy">
										<div class="form-group row">
											<label for="uploadToServer" class="col-md-2 col-sm-4 col-form-label">{{
												$t("OrderFields.uploadToServer")
											}}</label>
											<div :class="['col-md-10 col-sm-8 d-flex align-items-center', classes]">
												<b-form-checkbox
													v-model="orderForm.shareFiles"
													name="checkbox-upload"
													class="checkbox"
												/>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<CRow class="text-right">
							<CCol v-if="!isUpdate">
								<button
									:disabled="checkUploadFiles"
									class="px-4 ml-2 btn btn-outline-custom"
									type="submit"
								>
									{{ $t("Button.Save") }}
								</button>
								<button type="button" class="ml-2 btn btn-outline-danger" @click="cancel">
									{{ $t("Button.Cancel") }}
								</button>
							</CCol>
							<CCol v-if="isUpdate && !isUpdateOrder">
								<button
									v-if="!isCompletedOrder && displayWithRole"
									type="button"
									class="ml-2 btn btn-outline-danger"
									@click="showPopupDelete"
								>
									{{ $t("Button.Delete") }}
								</button>
								<button
									v-if="!isCompletedOrder"
									class="px-4 ml-2 btn btn-outline-custom"
									type="button"
									@click="updateOrder"
								>
									{{ $t("Button.Update") }}
								</button>
							</CCol>
							<CCol v-if="isUpdate && isUpdateOrder">
								<button
									:disabled="checkUploadFiles"
									class="px-4 ml-2 btn btn-outline-custom"
									type="submit"
								>
									{{ $t("Button.Save") }}
								</button>
								<button class="px-4 ml-2 btn btn-outline-danger" type="button" @click="back">
									{{ $t("Button.Back") }}
								</button>
							</CCol>
						</CRow>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>
		<CustomModal v-if="isDeleteUpdate" :submit-text="$t('Button.Yes')" @submit-modal="deleteUpdate">
			<template #content>
				{{ $t("OrderMessage.delete_update") }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isValidDate"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="closeValidDate"
		>
			<template #content>
				{{ $t("OrderMessage.valid_date") }}
			</template>
		</CustomModal>
		<CustomModal
			v-if="isDuplicateFile"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirmDuplicate"
			@cancel-modal="confirmDuplicate"
		>
			<template #content>
				{{ messageDuplicate }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import {
	ORDER_RULE,
	DATE_FORMAT,
	USER_ROLE,
	ORDER_STATUS,
	PARAMS,
	TYPE_UPLOAD,
} from "@/shared/plugins/constants"
import { createNamespacedHelpers } from "vuex"
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("orders")
import { debounce, uniq, join, uniqBy } from "lodash-es"
import { SEARCH_EMAIL } from "@/modules/customers/store/action-types"
import { RESET_CUSTOMER_FILTERS } from "@/modules/customers/store/mutation-types"
import { GET_TYPES_ORDER } from "@/modules/typeOrder/store/action-types"
import { SET_ORDER_TYPE_FILTERS, SET, RESET_STATE } from "@/modules/typeOrder/store/mutation-types"
import moment from "moment"
import { GET_ORDERS, TRANSLATE_TEXT } from "../store/action-types"
import { GET_TEMP_EMAILS } from "@/modules/email/store/action-types"
import { RESET_EMAIL_FILTERS } from "@/modules/email/store/mutation-types"
import { RESET_FILTERS_FORM_LIST } from "@/modules/forms/store/mutation-types"
import { RESET_ORDER_FILTERS, SET_ORDER_TYPES_SELECTED } from "../store/mutation-types"
import { GET_CUSTOMER_PRICES } from "../../customers/store/action-types"
import { SET_VARIABLE } from "../../customers/store/mutation-types"

const {
	mapActions: customersActions,
	mapMutations: customersMutations,
	mapState: customersState,
} = createNamespacedHelpers("customers")

export default {
	name: "OrderForms",
	components: {
		UploadFiles: () => import("@/shared/components/UploadFiles.vue"),
		TableAmount: () => import("../components/TableAmount"),
	},
	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		isUpdateOrder: {
			type: Boolean,
			default: false,
		},
		isRedirectForm: {
			type: Boolean,
			default: false,
		},
		addAnUpdate: {
			type: Boolean,
			default: false,
		},
		id: {
			type: [String, Number],
			default: null,
		},
		orderDetail: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			ORDER_RULE,
			uniq,
			TYPE_UPLOAD,
			formatDate: DATE_FORMAT,
			isPressConfirm: false,
			isValidDate: false,
			isDeleteUpdate: false,
			isCompleteUpdate: false,
			isSendEmail: false,
			isDuplicateFile: false,
			offset: PARAMS.OFFSET,
			limit: PARAMS.LIMIT,
			uploadFiles: [],
			resultFiles: [],
			orderTypes: [],
			orderTypesSelected: [],
			createdDate: moment(new Date()).format("DD-MM-YYYY HH:mm"),
			type_order_id: Number(new Date()),
			email_template_id: Number(new Date()),
			messageDuplicate: null,
			totalFiles: null,
			isUploadSuccess: true,
			isEditSystemPrice: false,
			orderForm: {
				receivedDate: null,
				orderTypes: [],
				status: null,
				customerId: null,
				customerName: null,
				title: null,
				description: null,
				translatedDescription: null,
				customerEmail: null,
				rule: null,
				formID: null,
				updateID: null,
				orderID: null,
				deadLine: null,
				time: null,
				amount: null,
				emailTemplateDescription: null,
				emailTemplateId: null,
				isComplete: 1,
				sendEmail: 1,
				originalOrderId: null,
				price: null,
				cost: null,
				dropbox: null,
				drive: null,
				driveSharedFolderLinks: [],
				dropboxSharedFolderLinks: [],
				shareFiles: true,
			},
		}
	},
	computed: {
		...mapState(["totalItems", "amounts", "typeOrderSelected"]),
		...customersState(["customerPrices"]),
		listStatus() {
			if (!this.addAnUpdate) {
				return [
					{
						name: this.$t("OrderFilterName.new_job"),
						value: ORDER_STATUS.NEW_JOB,
					},
					{
						name: this.$t("OrderFilterName.revision"),
						value: ORDER_STATUS.REVISION,
					},
					{
						name: this.$t("OrderFilterName.more_new_job"),
						value: ORDER_STATUS.MORE_NEW_JOB,
					},
					{
						name: this.$t("OrderFilterName.revision_more_new_job"),
						value: ORDER_STATUS.REVISION_MORE_NEW_JOB,
					},
				]
			}
			return [
				{
					name: this.$t("OrderFilterName.revision"),
					value: ORDER_STATUS.REVISION,
				},
				{
					name: this.$t("OrderFilterName.more_new_job"),
					value: ORDER_STATUS.MORE_NEW_JOB,
				},
				{
					name: this.$t("OrderFilterName.revision_more_new_job"),
					value: ORDER_STATUS.REVISION_MORE_NEW_JOB,
				},
			]
		},
		types() {
			return this.$store.state.typeOrder.typesOrder
		},
		currentUser() {
			return this.$store.state.authentication.currentUser
		},
		displayWithRole() {
			if (this.currentUser.role === USER_ROLE.QA) {
				return false
			}
			return true
		},
		isSuperAdmin() {
			if (this.currentUser.role === USER_ROLE.SUPER_ADMIN) {
				return true
			}
			return false
		},
		filterCustomer() {
			return this.$store.state.customers.recentFilters
		},
		checkIsUpdate() {
			if (!this.isUpdate || this.isUpdateOrder) {
				return true
			}
			return false
		},
		emailTemplateList() {
			return this.$store.state.email.tempEmails
		},
		formID() {
			return this.$route.query.formID || null
		},
		formDetail() {
			return this.$store.state.forms.formDetail
		},
		isDisabled() {
			if (!this.isUpdate || (this.isUpdate && this.isUpdateOrder)) {
				return false
			}
			return true
		},
		disableEmailTemp() {
			if (this.isSendEmail) {
				return false
			}
			return true
		},
		disableEmailDescription() {
			if (this.isSendEmail && this.displayWithRole) {
				return false
			}
			return true
		},
		isCompletedOrder() {
			if (this.isUpdate) {
				if (this.orderForm.status?.value === ORDER_STATUS.COMPLETED || this.isCompleteUpdate) {
					return true
				}
			}
			return false
		},
		getTitle() {
			return `${this.id} ${this.$t("OrdersTitle.Detail")}`
		},
		isShowSelectType() {
			if (this.isUpdate) {
				return true
			}
			return false
		},
		checkRequiredFile() {
			if (this.uploadFiles.length === 0) {
				return true
			}
			return false
		},
		checkUploadFiles() {
			if ((this.uploadFiles.length || this.resultFiles.length) && !this.isUploadSuccess) {
				return true
			}
			return false
		},
		newOrder() {
			if (!this.addAnUpdate) {
				return true
			}
			return false
		},
		newUpdateHasStatusRevision() {
			if (
				this.addAnUpdate &&
				!this.isUpdate &&
				this.orderForm.status.value === ORDER_STATUS.REVISION
			) {
				return true
			}
			return false
		},
	},
	watch: {
		orderDetail: {
			handler(val) {
				if (!val) return
				this.setUpData()
			},
			deep: true,
		},
		formDetail: {
			handler(val) {
				if (!val) return
				this.setUpData()
			},
			deep: true,
		},
		amounts: {
			handler: async function () {
				await this.getSystemPrice()
			},
			deep: true,
		},
		customerPrices: {
			handler: async function () {
				await this.getSystemPrice()
			},
			deep: true,
		},
		orderTypes: {
			handler(val) {
				if (!this.isUpdate) {
					if (val && val.length) {
						val = val.map(el => {
							return {
								...el,
								cost: 0,
								amount: 0,
							}
						})
					}
				}
				this.orderTypesSelected = val
			},
			deep: true,
		},
		"orderForm.customerId": {
			handler: async function (val) {
				if (val) await this.getUnitPriceWithCustomer()
			},
			deep: true,
		},
		"orderForm.price": {
			handler(val) {
				if (val && val.length > 10) {
					this.setFormFieldErr("actualPrice", "test")
				}
			},
			deep: true,
		},
	},
	created() {
		this.setUpData()
		this.getTypes()
	},
	beforeDestroy() {
		this.isPressConfirm = false
		this.isCreateSuccess = false
		this.orderTypesSelected = []
		this.$store.commit(`typeOrder/${RESET_STATE}`)
		this.$store.commit(`customers/${RESET_CUSTOMER_FILTERS}`)
		this.$store.commit(`email/${RESET_EMAIL_FILTERS}`)
		this.$store.commit(`forms/${RESET_FILTERS_FORM_LIST}`)
	},
	methods: {
		...mapActions({ GET_ORDERS, TRANSLATE_TEXT }),
		...mapMutations({ RESET_ORDER_FILTERS, SET_ORDER_TYPES_SELECTED, SET }),
		...customersActions({ GET_CUSTOMER_PRICES }),
		...customersMutations({ SET_VARIABLE }),
		async getTypes() {
			const params = {
				data: {},
				notLoading: true,
			}
			const res = await this.$store.dispatch(`typeOrder/${GET_TYPES_ORDER}`, params)
			return res
		},
		async getEmailTemplates() {
			if (this.isCompletedOrder) {
				const params = {
					notLoading: true,
				}
				const res = await this.$store.dispatch(`email/${GET_TEMP_EMAILS}`, params)
				return res.result
			}
		},

		async getLastToalToday(orderID) {
			const params = {
				data: {
					search: orderID,
					createdTo: this.comvertToISO(this.createdDate, false),
					createdFrom: this.comvertToISO(this.createdDate),
				},
				notLoading: true,
			}
			const res = await this.GET_ORDERS(params)
			return res.total
		},

		setOrderTypesWithAmount() {
			if (!this.amounts.length)
				return this.orderTypesSelected.map(el => {
					return {
						id: el.id,
						amount: el.amount,
					}
				})

			return uniqBy([...this.amounts, ...this.orderTypesSelected], "id").map(el => {
				return {
					id: el.id,
					amount: el.amount,
				}
			})
		},

		submitForm: async function () {
			const params = {
				status: this.orderForm.status.value,
				title: this.orderForm.title,
				description: this.orderForm.description,
				customerEmail: this.orderForm.customerEmail,
				customerId: this.orderForm.customerId,
				amount: this.orderForm.amount,
				rule: this.orderForm.rule,
				duration: parseFloat(this.orderForm.time),
				receivedDate: this.convertDateVnToISO(this.orderForm.receivedDate),
				deadLine: this.convertDateVnToISO(this.orderForm.deadLine),
				cost: parseInt(this.orderForm.cost),
				price: parseInt(this.orderForm.price),
				orderTypesWithAmount: this.setOrderTypesWithAmount(),
				dropboxSharedFolderLinks: this.orderForm.dropboxSharedFolderLinks,
				driveSharedFolderLinks: this.orderForm.driveSharedFolderLinks,
				shareFiles: this.orderForm.shareFiles,
				translatedDescription: this.orderForm.translatedDescription,
			}
			if (
				parseInt(!this.isUpdate) ||
				parseInt(this.orderForm.time) !== this.orderDetail?.duration
			) {
				const valid = this.compareDate(this.orderForm.deadLine)
				if (!valid) {
					return this.showValidDate()
				}
			}

			if (!this.isUpdate) {
				params["id"] = this.orderForm.orderID

				if (this.orderForm.originalOrderId) {
					params["originalOrderId"] = this.orderForm.originalOrderId
				}

				if (this.isRedirectForm) {
					params["formId"] = this.formDetail ? parseFloat(this.formDetail.id) : null
					const newFiles = [...this.uploadFiles].filter(el => el.file)
					if (newFiles.length) {
						params["files"] = newFiles
					}
					const oldFiles = this.uploadFiles.filter(el => !el.file)
					params["attachmentPaths"] = oldFiles.map(el => el.path)
				} else {
					// add new file
					params["files"] = this.uploadFiles
				}
			} else {
				const newRequestFiles = [...this.uploadFiles].filter(el => el.file)
				const newResultFiles = [...this.resultFiles].filter(el => el.file)
				if (newRequestFiles.length) {
					params["requestFiles"] = newRequestFiles
				}

				if (newResultFiles.length) {
					params["resultFiles"] = newResultFiles
				}

				const oldFileRequest = this.uploadFiles.filter(el => !el.file)
				const oldFileResult = this.resultFiles.filter(el => !el.file)

				if (oldFileRequest.length) {
					params["requestFilePaths"] = oldFileRequest.map(el => el.path)
				}
				if (oldFileResult.length) {
					params["resultFilePaths"] = oldFileResult.map(el => el.path)
				}

				params["orderId"] = this.id
				if (this.orderForm.isComplete) {
					params["isComplete"] = this.orderForm.isComplete
				}

				if (this.orderForm.sendEmail) {
					params["sendEmail"] = this.orderForm.sendEmail
				}

				if (this.orderForm.emailTemplateId) {
					params["emailTemplateId"] = this.orderForm.emailTemplateId.id
					params["emailTemplateDes"] = this.orderForm.emailTemplateDescription
				}
			}

			this.$emit("submit", params)
		},
		cancel: function () {
			this.$emit("cancel")
		},
		close() {
			this.$emit("close")
		},
		back() {
			this.$emit("back")
		},
		checkValidate(field) {
			if (this.$refs[field]) return this.$refs[field].validate()
		},
		async setUpData() {
			if (this.isUpdate && this.orderDetail) {
				this.orderForm = {
					...this.orderDetail,
					receivedDate: this.convertUTCtoDateVN(this.orderDetail.receivedDate),
					deadLine: this.convertUTCtoDateVN(this.orderDetail.deadLine),
					time: this.orderDetail.duration,
					updateID: this.orderDetail.id,
					formID: this.orderDetail.fromDisplayId,
					price: null,
				}
				this.createdDate = this.convertUTCtoDateVN(this.orderDetail.createdAt)
				this.orderTypes = this.orderDetail.orderTypes
				this.orderForm.status = this.listStatus.find(
					status => status.value === this.orderForm.status,
				)

				await this.SET(["amounts", this.orderDetail.orderTypes])

				if (!this.orderForm.status) {
					this.orderForm.status = {
						name: this.$t("OrderFilterName.complete"),
						value: ORDER_STATUS.COMPLETED,
					}
				}

				if (!this.orderForm.dropboxSharedFolderLinks) {
					this.orderForm.dropboxSharedFolderLinks = []
				}

				if (!this.orderForm.driveSharedFolderLinks) {
					this.orderForm.driveSharedFolderLinks = []
				}

				if (!this.isUpdateOrder) {
					this.orderForm.emailTemplateId = {
						id: this.orderDetail.emailTemplateId,
					}
				}

				if (this.orderForm.customerEmail) {
					await this.searchCustomers()
				}
				this.orderForm.rule = this.orderDetail.rule

				this.uploadFiles = [...this.orderDetail.uploadFiles]
				this.resultFiles = [...this.orderDetail.resultFiles]
			} else {
				if (this.isRedirectForm && this.formDetail) {
					this.orderForm.receivedDate = this.formDetail.received
					this.orderForm.customerEmail = this.formDetail.email
					this.orderForm.customerId = this.formDetail.customerId
					this.orderForm.customerName = this.formDetail.customerName
					this.orderForm.rule = this.formDetail.customerRule
					this.orderForm.title = this.formDetail.title
					this.orderForm.description = this.formDetail.description
					this.orderTypes = this.formDetail.orderTypes
					this.orderForm.formID = this.formDetail.displayId
					this.uploadFiles = [...this.formDetail.attachments]
					// await this.searchCustomers()
					// this.orderForm.status = {
					// 	name: this.$t("OrderFilterName.revision"),
					// 	value: ORDER_STATUS.REVISION,
					// }

					this.orderForm.status = this.listStatus.find(
						status => status.value === this.formDetail.orderStatus,
					)

					if (!this.formDetail.dropboxSharedFolderLinks) {
						this.orderForm.dropboxSharedFolderLinks = []
					} else {
						this.orderForm.dropboxSharedFolderLinks = this.formDetail.dropboxSharedFolderLinks
					}

					if (!this.formDetail.driveSharedFolderLinks) {
						this.orderForm.driveSharedFolderLinks = []
					} else {
						this.orderForm.driveSharedFolderLinks = this.formDetail.driveSharedFolderLinks
					}
				} else {
					this.orderForm.receivedDate = this.createdDate
					this.orderForm.status = {
						name: this.$t("OrderFilterName.new_job"),
						value: ORDER_STATUS.NEW_JOB,
					}
				}

				if (this.$route.query.original_order || this.$route.query.update_order) {
					this.orderForm.originalOrderId = this.$route.query.original_order || null
				}

				if (this.$route.query.update_order && !this.isRedirectForm) {
					// const orderFirst = await this.getOrderUpdateFirst()
					this.orderForm.title = this.orderDetail?.title
					this.orderForm.customerEmail = this.orderDetail?.customerEmail
					this.orderForm.customerId = this.orderDetail?.customerId
					this.orderForm.customerName = this.orderDetail?.customerName
					this.orderForm.rule = this.orderDetail?.rule
					// await this.searchCustomers()
					this.orderForm.status = {
						name: this.$t("OrderFilterName.revision"),
						value: ORDER_STATUS.REVISION,
					}
				}
				if (this.$route.query.update_order) {
					this.orderTypes = this.orderDetail?.orderTypes
					this.orderForm.orderID = this.orderDetail?.id
				}
				// this.getSystemPrice()
			}
		},
		async getOrderUpdateFirst() {
			const params = {
				data: {
					...this.recentFilters,
					originalOrderId: this.orderForm.originalOrderId,
				},
				notLoading: false,
			}
			const res = await this.GET_ORDERS(params)
			return res.data
		},
		async searchCustomers() {
			if (!this.orderForm.customerEmail) return
			const params = {
				data: {
					...this.filterCustomer,
					search: this.orderForm.customerEmail,
				},
				notLoading: true,
			}
			const res = await this.$store.dispatch(`customers/${SEARCH_EMAIL}`, params)
			if (res.length >= 1) {
				const customer = res[0]
				this.orderForm.customerId = customer?.id
				this.orderForm.customerName = customer?.name
				this.orderForm.rule = customer?.rule
				// this.getUnitPriceWithCustomer()
			} else {
				this.orderForm.customerId = null
				this.orderForm.customerName = null
				this.SET_VARIABLE(["customerPrices", []])
			}
		},
		async getUnitPriceWithCustomer() {
			const params = {
				data: {
					customerId: this.orderForm.customerId,
				},
				notLoading: true,
			}
			await this.GET_CUSTOMER_PRICES(params)
		},
		searchDebounce: debounce(async function () {
			const { valid } = await this.$refs.email.validate()
			if (valid) {
				await this.searchCustomers()
			} else {
				this.orderForm.customerId = null
				this.orderForm.customerName = null
			}
		}, process.env.VUE_APP_DEBOUNCE_TIME),
		handleDeleteFile(index) {
			this.uploadFiles.splice(index, 1)
		},
		getTotalFiles(total) {
			this.totalFiles = total
		},
		async handleUploadFiles(files) {
			this.isUploadSuccess = false
			this.uploadFiles.push(...files)
			const newFiles = this.uploadFiles
			const result = await this.getFileNameDuplicate(newFiles)
			if (result.length) {
				const fileName = result.map(el => el.name)
				this.messageDuplicate = `${this.$t("messages.duplicate.left")} ${join(
					fileName,
					", ",
				)} ${this.$t("messages.duplicate.right")}`
				this.isDuplicateFile = true
				this.$store.commit("set", ["modalShow", true])
			}
			this.uploadFiles = newFiles
			if (this.uploadFiles.length === this.totalFiles) this.isUploadSuccess = true
			// this.uploadFiles = [...this.uploadFiles, ...files]
		},
		async handleUploadResultFiles(files) {
			this.isUploadSuccess = false
			const newFiles = [...this.resultFiles, ...files]
			const result = await this.getFileNameDuplicate(newFiles)
			if (result.length) {
				const fileName = result.map(el => el.name)
				this.messageDuplicate = `${this.$t("messages.duplicate.left")} ${join(
					fileName,
					", ",
				)} ${this.$t("messages.duplicate.right")}`
				this.isDuplicateFile = true
				this.$store.commit("set", ["modalShow", true])
			}
			this.resultFiles = newFiles
			if (this.resultFiles.length === this.totalFiles) this.isUploadSuccess = true
			// this.resultFiles = [...this.resultFiles, ...files]
		},
		handleDeleteResultFile(index) {
			this.resultFiles.splice(index, 1)
		},
		selectTypes(selected) {
			return selected.map(el => el.id)
		},
		selectStatus(value) {
			this.orderForm.status = value.value
		},
		clearSelected(type, removed) {
			switch (type) {
				case "type":
					this.orderForm.orderTypes = []
					this.orderTypesSelected = []
					this.orderForm.updateID = null
					this.orderForm.orderID = null
					let newAmounts = [...this.amounts]
					newAmounts = newAmounts.filter(el => el.id !== removed.id)
					this.SET(["amounts", newAmounts])
					break
				case "status":
					this.orderForm.status = null
					break

				default:
					break
			}
		},
		async handleSelectTime() {
			const hour = parseInt(this.orderForm.time)

			if (hour < 0) return (this.orderForm.time = null)

			const received = moment(this.orderForm.receivedDate, "DD-MM-YYYY HH:mm").format()
			const newDate = new Date(received)
			newDate.setHours(newDate.getHours() + hour)
			this.orderForm.deadLine = moment(newDate).format("DD-MM-YYYY HH:mm")
		},
		showPopupDelete() {
			this.$store.commit("set", ["modalShow", true])
			this.isDeleteUpdate = true
		},
		deleteUpdate() {
			this.$emit("deleteUpdate")
			this.$store.commit("set", ["modalShow", false])
			this.isDeleteUpdate = false
		},
		updateOrder() {
			this.$emit("updateOrder")
		},
		changeIsCompleteUpdate(value) {
			this.isCompleteUpdate = value
			if (value) {
				this.orderForm.isComplete = 1
			} else {
				this.orderForm.isComplete = 0
			}
			if (!value) {
				this.orderForm.emailTemplateId = null
				this.orderForm.emailTemplateDescription = null
				this.isSendEmail = false
			}
		},
		ChangeIsSendEmail(value) {
			this.getEmailTemplates()
			this.isSendEmail = value
			if (value) {
				this.orderForm.sendEmail = 1
			} else {
				this.orderForm.sendEmail = 0
				this.orderForm.emailTemplateId = null
				this.orderForm.emailTemplateDescription = null
			}
		},
		selectEmailTemplate(selected) {
			this.orderForm.emailTemplateDescription = selected.description
			this.orderForm.emailTemplateId = selected.id
			this.$refs.email_select.reset()
			this.$refs.email_description.reset()
		},
		handleSearchCustomer() {
			if (this.orderForm.customerEmail) this.isEditSystemPrice = true
			this.searchDebounce()
		},
		amountChange() {
			this.isEditSystemPrice = true
		},
		isAppendLoading(array) {
			return array.length >= this.limit
		},
		async closeDropDown(type) {
			this.offset = 0
			switch (type) {
				case "type":
					await this.$store.commit(`typeOrder/${SET}`, ["typesOrder", []])
					await this.$store.commit(`typeOrder/${SET_ORDER_TYPE_FILTERS}`, { offset: this.offset })
					this.type_order_id += 1
					break
				case "email":
					await this.$store.commit(`email/${SET}`, ["typesOrder", ["tempEmails", []]])
					this.email_template_id += 1
					break

				default:
					break
			}
		},
		compareDate(date, isDeadline = true) {
			const now = new Date().toISOString()
			const newDate = this.convertDateVnToISO(date)
			if (isDeadline) {
				return newDate > now
			}
			return newDate < now
		},
		showValidDate() {
			this.isValidDate = true
			this.$store.commit("set", ["modalShow", true])
		},
		closeValidDate() {
			this.isValidDate = false
			this.$store.commit("set", ["modalShow", false])
		},
		confirmDuplicate() {
			this.uploadFiles = uniqBy(this.uploadFiles, "name")
			this.resultFiles = uniqBy(this.resultFiles, "name")
			this.isDuplicateFile = false
			this.$store.commit("set", ["modalShow", false])
		},
		async getSystemPrice() {
			if (!this.orderForm.customerId) {
				this.orderForm.cost = null
				this.orderForm.price = null
				if (this.isUpdate && !this.orderForm.price) {
					this.orderForm.price = this.orderDetail.price
				}
				return
			}

			let total = 0
			this.customerPrices.forEach(item => {
				this.amounts.forEach(el => {
					if (el.id === item.typeId) {
						return (total = total + el.amount * item.cost)
					}
				})
			})
			if (this.isEditSystemPrice || this.orderForm.customerId) {
				if (!this.isUpdate || this.orderDetail.status !== ORDER_STATUS.COMPLETED) {
					this.orderForm.cost = total.toString()
				}
			}

			if (this.isUpdate) {
				return (this.orderForm.price = this.orderDetail.price)
			}
			return (this.orderForm.price = this.orderForm.cost)
		},
		setFormFieldErr(field, err) {
			if (this.$refs[field]) this.$refs[field].setErrors([err])
		},
		async translateText() {
			if (!this.orderForm.description) return
			const params = {
				text: this.orderForm.description,
			}
			const res = await this.TRANSLATE_TEXT(params)
			if (res) {
				this.orderForm.translatedDescription = res.text
			}
		},
		async addLink(type) {
			const validate = await this.$refs[type].validate()
			const value = this.$refs[type].value

			if (!validate.valid || !value) {
				return
			}

			if (type === "dropbox") {
				this.orderForm.dropboxSharedFolderLinks.unshift(this.orderForm.dropbox)
				this.orderForm.dropbox = null
			} else {
				this.orderForm.driveSharedFolderLinks.unshift(this.orderForm.drive)
				this.orderForm.drive = null
			}
		},
		removeLink(type, index) {
			if (type === "dropbox") {
				this.orderForm.dropboxSharedFolderLinks.splice(index, 1)
			} else {
				this.orderForm.driveSharedFolderLinks.splice(index, 1)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.custom__input {
	width: 100%;
	margin: auto;
}
.remove-resize {
	resize: none;
}

.actual-price {
	max-width: 150px;
	min-width: 100px;
}

.options {
	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $grey-4;
		padding-bottom: 5px;
		font-size: 13px;

		.order__id {
			font-style: italic;
			text-transform: uppercase;
		}
		.order__type {
			font-weight: bold;
		}

		&:last-child {
			border: none;
		}

		&__info {
			width: 50%;
			display: flex;
			justify-content: space-between;
		}
	}
}
.options__border {
	max-height: 300px;
	border: 1px solid $border-input;
	border-top: none;
	background: $white;
	z-index: 10;
	width: calc(100% - 30px);
	position: absolute;
	overflow-y: auto;

	.wrap {
		padding: 14px;

		&:hover {
			background: rgba(0, 134, 217, 0.05) !important;
			color: #0086d9 !important;
		}

		.item__hover {
			cursor: pointer;
			border-bottom: 1px solid $grey-4;
		}

		&:last-child {
			.item__hover {
				border: none;
				padding-bottom: 0;
			}
		}
	}
}

.icon {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	z-index: 10;
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox-url {
	width: calc(100% - 60px);
}

.btn-delete {
	min-width: 40px;
	height: 35px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	svg {
		font-size: 18px;
	}
}

.dropbox {
	&-item {
		padding-left: 12px;
		border: 1px solid $border-input;
		border-radius: 0.25rem;
		border-right: unset;
	}
}

.disabled-link {
	background: $grey-5;
	cursor: not-allowed;
}
</style>
